<template>
	<div class="performance performance--overview">
		<div class="performance__col performance__col--2">
			<number-bars class="number-bars--on-red" :title="`GSD: DVP Total Value In Net`" unit="( in trillions )" :numbers="gsdValue" />
			<number-bars class="number-bars--on-red number-bars--align-left" :title="`GSD: GCF Repo Total Value In Net`" unit="( in trillions )" :numbers="totalGcf" />
		</div>
	</div>
</template>

<script>
import NumberBars from '@/components/NumberBars'

export default {
	name: 'FixedIncome2',
	components: {
		NumberBars,
	},
	data: () => ({
		gsdValue: [
			{
				value: 1507.20,
				label: '2020',
				options: {
					prefix: '$',
					decimalPlaces: 2,
				},
			},
			{
				value: 1477.70,
				label: '2019',
				options: {
					prefix: '$',
					decimalPlaces: 2,
				},
			},
			{
				value: 1165.30,
				label: '2018',
				options: {
					prefix: '$',
					decimalPlaces: 2,
				},
			},

		],
		totalGcf: [
			{
				value: 319,
				label: '2020',
				options: {
					prefix: '$',
				},
			},
			{
				value: 300,
				label: '2019',
				options: {
					prefix: '$',
				},
			},
			{
				value: 300,
				label: '2018',
				options: {
					prefix: '$',
				},
			},

		],
	}),
}
</script>

<style scoped lang="scss">

</style>
